export const startPosition = [
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  'white',
  'black',
  null,
  null,
  null,
  null,
  null,
  null,
  'black',
  'white',
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null
];

export function makeMove(board, color, x, y) {
  const result = [...board];

  result[y * 8 + x] = color;

  let validMove = false;

  for ({ x, y } of toFlip(board, color, x, y)) {
    validMove = true;
    result[y * 8 + x] = color;
  }

  return validMove ? result : null;
}

function* toFlip(board, color, x, y) {
  for (const direction of directions()) {
    const buffer = [];
    for (const { value, x: nx, y: ny } of squares(board, x, y, direction)) {
      if (value == color) {
        yield* buffer;
        break;
      } else {
        buffer.push({ x: nx, y: ny });
      }
    }
  }
}

const hasAny = iterator => {
  for (let _ of iterator) {
    return true;
  }
  return false;
};

export function canMove(board, color) {
  return board.some(
    (state, index) =>
      state === null &&
      hasAny(toFlip(board, color, index % 8, Math.floor(index / 8)))
  );
}

function getValue(board, x, y) {
  return y >= 0 && x >= 0 && y < 8 && x < 8 && board[y * 8 + x];
}

function* squares(board, x, y, direction) {
  let value;
  while ((({ x, y } = direction(x, y)), (value = getValue(board, x, y)))) {
    yield { value, x, y };
  }
}

function* directions() {
  yield (x, y) => ({ x, y: y + 1 });
  yield (x, y) => ({ x, y: y - 1 });
  yield (x, y) => ({ x: x + 1, y });
  yield (x, y) => ({ x: x - 1, y });
  yield (x, y) => ({ x: x + 1, y: y + 1 });
  yield (x, y) => ({ x: x + 1, y: y - 1 });
  yield (x, y) => ({ x: x - 1, y: y + 1 });
  yield (x, y) => ({ x: x - 1, y: y - 1 });
}
