import { makeMove, startPosition } from './othello';
import { initialise, makeMove as postMove, getAnotherMove } from './api';
import './index.css';

const pieces = [...document.querySelectorAll('.piece')];
const spinner = document.querySelector('.spinner');
const blackScore = document.querySelector('.score.black');
const whiteScore = document.querySelector('.score.white');

const playAs = 'black';
let board = startPosition;
let move = 'black';

let identifier;

initialise().then(response => {
  handleResponse(response);
  spinner.remove();
});

pieces.forEach((piece, index) => {
  const y = Math.floor(index / 8);
  const x = index - y * 8;

  piece.addEventListener('click', async () => {
    if (!piece.classList.contains('move')) {
      return;
    }
    board = makeMove(board, playAs, x, y);
    move = { white: 'black', black: 'white' }[move];
    updateBoard(board, []);
    const response = await postMove(x, y, identifier);
    handleResponse(response);
  });
});

async function handleResponse(response) {
  board = response.rows;
  identifier = response.identifier;
  if (!response.moves.length) {
    setTimeout(() => updateBoard(response.rows, response.moves), 400);
  } else if (response.turn !== playAs) {
    const xxx = await getAnotherMove(identifier);
    setTimeout(() => {
      updateBoard(response.rows, response.moves);
      handleResponse(xxx);
    }, 400);
  } else {
    setTimeout(() => updateBoard(response.rows, response.moves), 400);
  }
}

function updateBoard(board, moves) {
  const movesUp = moves.map(({ x, y }) => y * 8 + x);
  const white = board.filter(x => x === 'white').length;
  const black = board.filter(x => x === 'black').length;

  whiteScore.innerHTML = white;
  blackScore.innerHTML = black;

  whiteScore.classList.toggle('winning', white > black);
  blackScore.classList.toggle('winning', black > white);

  pieces.forEach((piece, index) => {
    const state = board[index];
    const isValidMove = movesUp.includes(index);

    piece.classList.toggle('white', state === 'white');
    piece.classList.toggle('black', state === 'black');
    piece.classList.toggle('move', isValidMove);

    if (state) {
      setTimeout(() => {
        piece.classList.add('active');
      }, 100);
    }
  });
}

//updateBoard(startPosition);
