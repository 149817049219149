export const makeMove = (x, y, identifier) =>
  postData(process.env.API + '/api/game/' + identifier, { x, y });

export const getAnotherMove = identifier =>
  postData(process.env.API + '/api/game/' + identifier, {});

export const initialise = () => get(process.env.API + '/api/game');

const get = url => fetch(url).then(r => r.json());

const postData = (url, data) =>
  fetch(url, {
    body: JSON.stringify(data),
    headers: {
      'content-type': 'application/json'
    },
    method: 'POST'
  }).then(r => r.json());
